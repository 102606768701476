import React, { useState } from 'react';
import { Link } from 'gatsby';
import HeaderWrapper, {
  NavbarWrapper,
  Logo,
  MenuWrapper,
  MainMenuContainer,
  TopicButton,
  TopicCloseIcon,
} from './navbar.style';
import LogoImage from '../../images/logo.png';
import { MenuItem, MenuItemWrapper } from '../navbar/navbar.style';
import { MainMenu } from './main-menu';

type NavbarProps = {
  className?: string;
};

const Navbar: React.FunctionComponent<NavbarProps> = ({ className, ...props }) => {
  const [isTopicsMenuOpen, toggleTopicsMenu] = useState(false);

  // Add all classs to an array
  const addAllClasses = ['header'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  if (typeof window !== 'undefined') {
    window.document.body.style.overflow = isTopicsMenuOpen ? 'hidden' : 'auto';
  }

  return (
    <HeaderWrapper className={addAllClasses.join(' ')} {...props}>
      <NavbarWrapper className="navbar">
        <Logo>
          <Link to="/">
            <img src={LogoImage} alt="logo" />
          </Link>
        </Logo>
        <MenuWrapper>
          <MenuItemWrapper>
            <MenuItem>
              {!isTopicsMenuOpen && (
                <TopicButton onClick={() => toggleTopicsMenu(!isTopicsMenuOpen)}>
                  Categories
                </TopicButton>
              )}
              {isTopicsMenuOpen && (
                <TopicCloseIcon
                  onClick={() => toggleTopicsMenu(!isTopicsMenuOpen)}
                ></TopicCloseIcon>
              )}
              <MainMenuContainer className={isTopicsMenuOpen ? 'opened' : ''}>
                <MainMenu></MainMenu>
              </MainMenuContainer>
            </MenuItem>
          </MenuItemWrapper>
        </MenuWrapper>
      </NavbarWrapper>
    </HeaderWrapper>
  );
};

export default Navbar;
