import * as React from 'react';
import SocialProfile from '../../components/social-profile/social-profile';
import FooterWrapper, {
  FooterCol,
  Infos,
  FooterTitle,
  FooterContent,
  Menu,
  DMCABlock,
} from './footer.style';

import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from 'react-icons/io';

const MenuItems = [
  {
    label: 'Home',
    url: '/'
  },
  {
    label: 'Terms Of Use',
    url: '/terms-of-use'
  },
  {
    label: 'Contact Us',
    url: '/contact-us',
  }
];

const SocialLinks = [
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/dailygoodwishes/',
  },
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/dailygoodwishes',
  }
];

type FooterProps = {
  children: React.ReactNode;
};

const Footer: React.FunctionComponent<FooterProps> = ({
  children,
  ...props
}) => {
  return (
    <FooterWrapper {...props}>
      <FooterCol>
        <FooterContent>
          {MenuItems.map((item, index) => (
            <Menu key={index} to={item.url}>
              {item.label}
            </Menu>
          ))}
        </FooterContent>
      </FooterCol>

      <FooterCol>
        <FooterTitle>Follow Us</FooterTitle>

        <SocialProfile items={SocialLinks} />
      </FooterCol>

      <FooterCol>
        <Infos>
        © 2021-present &copy;&nbsp;
          <a href="/"> Daily Good Wishes - All rights reserved.</a>
          <DMCABlock>
            <a href="//www.dmca.com/Protection/Status.aspx?ID=109da2cb-a54e-4e55-aaf2-e225ed32dfe1" title="DMCA.com Protection Status" className="dmca-badge"> <img src ="https://images.dmca.com/Badges/dmca_protected_14_120.png?ID=109da2cb-a54e-4e55-aaf2-e225ed32dfe1"  alt="DMCA.com Protection Status" /></a>  <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
          </DMCABlock>
        </Infos>
      </FooterCol>
    </FooterWrapper>
  );
};

export default Footer;
