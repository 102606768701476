const slugify = require('slugify');

export function removeSpecialCharactersFromText(slug) {
  return slug = slug.replace(/'/g, '');
}

export function generateSlug(text) {
  text = removeSpecialCharactersFromText(text);
  return slugify(text);
}

export function generateGroupPagePath(group) {
  return slugify(group);
}

export function generateCategoryPagePath(group, category) {
  return `${generateGroupPagePath(group)}/${generateSlug(category)}`;
}

export function generateTagPagePath(tag) {
  return `/tags/${generateSlug(tag)}`;
}