import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { IoIosClose } from 'react-icons/io';

const HeaderWrapper = styled.header`
  position: relative;
  z-index: 99999;
  transition: 0.25s ease-in-out;
  background-color: #fff;
  border-bottom: 1px solid #DBDBDB;
  box-shadow: 0 0 15px rgba(0,0,0,.30);
`;

export const NavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
  background-color: #fff;
  transition: 0.25s ease-in-out;
  margin: 0 auto;
  @media (max-width: 1400px) {
    padding: 25px 0x;
  }
  @media (max-width: 990px) {
    padding: 15px 45px;
  }
  @media (max-width: 575px) {
    padding: 15px 15px;
    min-height: 62px;
  }
  @media (min-width: 990px) {
    width: 900px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
`;

export const Logo = styled.div`
  margin-right: 50px;
  flex-shrink: 0;
  @media (max-width: 990px) {
    margin: 0px;
  }
  @media (max-width: 575px) {
    max-width: 210px;
    margin: 0;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const MenuItemWrapper = styled.ul`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const MenuItem = styled.li`
  list-style: none;
  a {
    white-space: nowrap;
    font-size: ${themeGet('fontSizes.3', '15')}px;
    line-height: ${themeGet('lineHeights.normal', '1')};
    font-weight: ${themeGet('fontWeights.3', '400')};
    color: ${themeGet('colors.lightTextColor', '#757575')};
    transition: 0.15s ease-in-out;

    &:hover {
      color: ${themeGet('colors.textColor', '#292929')};
    }
    &.active-link {
      color: ${themeGet('colors.textColor', '#292929')};
      font-weight: ${themeGet('fontWeights.4', '500')};
    }
  }

  @media (max-width: 400px) {
    margin-left: 0;
  }
`;

export const MainMenuContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 70px);
  position: absolute;
  top: 70px;
  left: 0;
  overflow-y: auto;
  background-color: #fff;
  display: none;

  &.opened {
    display: block;
  }

  @media (max-width: 1400px) {
    top: 70px;
    height: calc(100vh - 70px);
  }

  @media (max-width: 990px) {
    top: 60px;
    height: calc(100vh - 60px);
  }
`;

export const TopicButton = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  background-color: #d10068de;
  padding: 9px 20px;
  border-radius: 3px;
  transition: background-color 0.3s;
  font-family: sans-serif;

  &:hover {
    background-color: #d10068;
  }

  @media (max-width: 575px) {
    padding: 5px 10px;
    font-size: 14px;
  }
`;

export const TopicCloseIcon = styled(IoIosClose)`
  font-size: 28px;
  color: #757575;
  cursor: pointer;

  &:hover {
    color: #292929;
  }
`
export default HeaderWrapper;
