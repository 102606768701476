import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from '@emotion/styled';

import { generateGroupPagePath, generateCategoryPagePath } from '../../utils';

const MainNav = styled.nav`
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
    margin: 0 auto;
    
    @media (min-width: 990px) {
      width: 900px;
    }

    @media (min-width: 1200px) {
      width: 1170px;
    }

    @media (max-width: 1400px) {
      padding: 30px 0px;
    };

    @media (max-width: 990px) {
      padding: 30px 45px;
    }

    @media (max-width: 575px) {
      padding: 30px 15px;
    };
  `;

  const GroupNavSection = styled.section`
    flex: 1 0 25rem;
    margin: 1.5rem;
    max-width: 100%;

    @media (max-width: 575px) {
      margin: 1.5rem 0;
    };
  `;

  const GroupHeader = styled.header`
    display: block;
    color: #d10068;
    text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
    margin-bottom: 1.4rem;
  `;

  const CategoryLink = styled(Link)`
    text-transform: uppercase;
    text-decoration: none;
    color: '#000';
    display: block;
    padding: 1rem 0;
  `;


interface MenuData {
  data?: { groups: { group: string; nodes: { frontmatter: { category: string } }[] }[] };
}

function prepareMenuFromMenuData(menuData: MenuData) {
  return menuData.data.groups.map(groupItem => {
    const group = groupItem.group;
    return {
      title: group,
      slug: `/${generateGroupPagePath(group)}`,
      categories: groupItem.nodes.filter(node => !!node.frontmatter.category).reduce((categoryMenuItems: any[], node) => {
        const category = node.frontmatter.category;
        const isCategoryExists = !!categoryMenuItems.find(
          categoryMenuItem => categoryMenuItem.title === category
        );
        if (!isCategoryExists) {
          categoryMenuItems.push({
            title: category,
            slug: `/${generateCategoryPagePath(group, category)}`
          });
        }
        return categoryMenuItems;
      }, [])
    };
  });
}

export function MainMenu() {
  const menuData: MenuData = useStaticQuery(graphql`
    query MainMenuQuery {
      data: allMdx {
        groups: group(field: frontmatter___group) {
          group: fieldValue
          nodes {
            frontmatter {
              category
            }
          }
        }
      }
    }
  `);

  const menu = prepareMenuFromMenuData(menuData);

  return (
    <>
      <MainNav>
        {menu.map((group, index) => (
          <GroupNavSection>
            <GroupHeader>{group.title}</GroupHeader>
            <nav key={`nav-${index}`}>
              {group.categories.map((category, categoryIndex) => (
                <CategoryLink key={categoryIndex} to={category.slug}>
                  {category.title}
                </CategoryLink>
              ))}
            </nav>
          </GroupNavSection>
        ))}
      </MainNav>
    </>
  );
}
