import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Link } from 'gatsby';

const FooterWrapper = styled.footer`
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;

  @media (max-width: 990px) {
    padding-top: 50px;
    flex-wrap: wrap;
  }
  @media (min-width: 990px) {
    width: 900px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
`;

export const FooterCol = styled.div`
  flex: 0 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:last-child {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 990px) {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 30px;

    &:last-child {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 50px 0 0;
    }
  }

  @media (max-width: 690px) {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;

    &:last-child {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media (max-width: 460px) {
    flex: 0 0 100%;
    max-width: 100%;
    align-items: flex-start;

    &:last-child {
      flex: 0 0 100%;
      max-width: 100%;
      align-items: flex-start;
    }
  }
`;

export const Infos = styled.span`
  font-size: ${themeGet('fontSizes.3', '15')}px;
  font-weight: ${themeGet('fontWeights.3', '400')};
  line-height: 1.5;
  color: #fff;

  a {
    color: #fff;
  }
`;

export const FooterTitle = styled.span`
  font-size: ${themeGet('fontSizes.3', '15')}px;
  font-weight: ${themeGet('fontWeights.6', '700')};
  line-height: 1.5;
  color: #fff;
  margin-right: 30px;
  flex-shrink: 0;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-shrink: 0;

  @media (max-width: 460px) {
    align-items: flex-start;
  }
`;

export const Menu = styled(Link)`
  font-size: ${themeGet('fontSizes.3', '15')}px;
  font-weight: ${themeGet('fontWeights.3', '400')};
  line-height: 1.5;
  color: #fff;
  margin-bottom: 10px;
  text-transform: capitalize;
  transition: color 0.35s;
  margin: 0 5px;

  &:hover {
    color: ${themeGet('colors.primary', '#D10068')};
  }

  &:not(&:last-child)::after {
    content: '|';
    margin-left: 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DMCABlock = styled.div`
  margin-top: 24px;
`;

export default FooterWrapper;
