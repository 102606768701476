import React from 'react';
import Sticky from 'react-stickynode';
import styled, { ThemeProvider } from 'styled-components';
import Navbar from './navbar/navbar';
import Footer from './footer/footer';
// import InstagramShowcase from './instagram-showcase';
import ResetCss from './reset-css';
import { theme } from '../theme';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  padding: 30px 15px;
  background-color: #1c181e;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 30px 20px;
  }
`;

type LayoutProps = {
  children: React.ReactNode;
  isHideInstagram?: boolean;
};

const Layout: React.FunctionComponent<LayoutProps> = ({ children, isHideInstagram }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <ResetCss />
        <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
          <Navbar />
        </Sticky>

        {children}

        <Wrapper>
          {/* {!isHideInstagram && <InstagramShowcase />} */}

          <Footer>
            Copyright &copy; {new Date().getFullYear()}
            <a href="https://www.dailygoodwishes.com">Daily Good Wishes</a>
          </Footer>
        </Wrapper>
      </>
    </ThemeProvider>
  );
};

export default Layout;
